<template>
  <div id="stats-month">
    <StatsAccountMonth />
  </div>
</template>

<script>
import StatsAccountMonth from '../../../components/stats/account/StatsAccountMonth'

export default {
  components: {
    StatsAccountMonth
  }
}
</script>
